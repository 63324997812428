.container {
    text-align: center;
    padding: 15px;
    background-color: #4295F9;
    height: 100vh;
    color: white;
  }
  
  .container button {
    cursor: pointer;
    padding: 15px 30px;
    border-radius: 5px;
    margin-top: 30px;
    min-width: 280px;
  }


.App-logo {
    height: 10vmin;
    pointer-events: none;
  }
  
  